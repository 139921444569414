import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@shadcn/lib/utils';
import Spinner from '../../_icons/Spinner';

const buttonVariants = cva(
	'w-fit gap-4 justify-center items-center inline-flex box-border leading-4 transition ease-out duration-300 hover:transition-all',
	{
		variants: {
			variant: {
				solid: 'border font-semibold disabled:bg-black disabled:bg-opacity-[0.08] disabled:text-black disabled:text-opacity-[0.16] disabled:border-opacity-0 disabled:cursor-not-allowed',
				outlined:
					'box-content border border-solid disabled:border-black disabled:border-opacity-[0.16] disabled:text-black disabled:text-opacity-[0.16] disabled:cursor-not-allowed',
				ghost: 'font-semibold px-0 hover:underline hover:decoration-1 disabled:text-black disabled:text-opacity-[0.16] disabled:cursor-not-allowed disabled:hover:no-underline',
				icon: 'hover:bg-gold-light-8 rounded-full border-opacity-0 bg-transparent p-2',
			},
			intent: {
				primary: '',
				secondary: '',
				tertiary: '',
				success: '',
				error: '',
				disabled: '',
			},
			size: {
				large: '',
				small: '',
			},
		},
		defaultVariants: {
			variant: 'solid',
			intent: 'primary',
			size: 'large',
		},
		compoundVariants: [
			{
				variant: 'solid',
				intent: 'primary',
				className:
					'text-white [&_svg>path]:stroke-white bg-muted-navy hover:bg-brand-navy ',
			},
			{
				variant: 'solid',
				size: 'large',
				className: 'py-6 px-8 rounded-2xl',
			},
			{
				variant: 'solid',
				size: 'small',
				className: 'py-4 px-6 rounded-xl text-sm leading-4',
			},
			{
				variant: 'solid',
				intent: 'secondary',
				className:
					'text-white [&_svg>path]:stroke-white bg-gold-light border-gold-light hover:bg-gold',
			},
			{
				variant: 'solid',
				intent: 'success',
				className:
					'text-white [&_svg>path]:stroke-white bg-success border-success hover:bg-muted-success',
			},
			{
				variant: 'solid',
				intent: 'error',
				className:
					'text-white [&_svg>path]:stroke-white bg-error border-error hover:bg-muted-error',
			},
			{
				variant: 'outlined',
				intent: 'primary',
				className:
					'bg-transparent border-muted-navy text-muted-navy hover:border-gold-light hover:text-gold-light [&_svg>path]:hover:stroke-gold-light',
			},
			{
				variant: 'outlined',
				intent: 'secondary',
				className:
					'bg-transparent border-gold-light text-muted-navy hover:border-gold hover:text-gold',
			},
			{
				variant: 'outlined',
				intent: 'tertiary',
				className:
					'bg-transparent border-white text-white hover:border-gold hover:text-gold',
			},
			{
				variant: 'outlined',
				size: 'large',
				className: 'py-6 px-8 rounded-2xl',
			},
			{
				variant: 'outlined',
				size: 'small',
				className: 'py-4 px-6 rounded-xl text-sm leading-4',
			},
			{
				variant: 'ghost',
				intent: 'primary',
				className: 'text-brand-navy',
			},
			{
				variant: 'ghost',
				intent: 'secondary',
				className: 'text-gold-light [&_svg>path]:stroke-gold-light',
			},
			{
				variant: 'ghost',
				intent: 'tertiary',
				className: 'text-white [&_svg>path]:stroke-white',
			},
			{
				variant: 'ghost',
				intent: 'success',
				className: 'text-success [&_svg>path]:stroke-success',
			},
			{
				variant: 'ghost',
				intent: 'error',
				className: 'text-error [&_svg>path]:stroke-error',
			},
			{
				variant: 'ghost',
				size: 'large',
				className: 'py-6 px-0',
			},
			{
				variant: 'ghost',
				size: 'small',
				className: 'py-4 text-sm',
			},
		],
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	iconButton?: boolean;
	variant?: 'solid' | 'outlined' | 'ghost' | 'icon';
	intent?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'error';
	size?: 'small' | 'large';
	className?: string;
	disabled?: boolean;
	loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, intent, size, asChild = false, loading = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';

		return (
			<div className="relative inline-block">
				<Comp
					className={cn(
						buttonVariants({ variant, intent, size, className }),
						loading && 'pointer-events-none opacity-60',
					)}
					ref={ref}
					{...props}
				/>

				{loading && (
					<div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-40">
						<span className="animate-spin">
							<Spinner size="large" />
						</span>
					</div>
				)}
			</div>
		);
	},
);
Button.displayName = 'Button';

export { Button, buttonVariants };
